var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.colse,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "scanPanel"
        }, [_c('div', {
          staticClass: "scanMain"
        }, [_c('canvas', {
          ref: "scanCanvas",
          attrs: {
            "id": "scanCanvas"
          }
        })]), _c('div', {
          staticClass: "closeBtn"
        }, [_c('van-button', {
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.onCloseScan
          }
        }, [_vm._v("取消")])], 1)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };