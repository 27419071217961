import { mapGetters } from 'vuex';
import common from '@mixins/common';
import { isIOS } from '@utils/utils';
import jsQR from 'jsqr';
import { useUserCoupon } from '@api/coupon';
export default {
  name: 'couponScan',
  mixins: [common],
  data() {
    return {
      scanValue: ''
    };
  },
  computed: {
    ...mapGetters('user', ['projectInfo']),
    phoneType() {
      return isIOS();
    }
  },
  created() {
    const {
      token,
      projectId
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        projectId
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        projectId
      });
    }
    if (this.phoneType === 'ios') {
      window.webkit.messageHandlers.scanQRCode.postMessage('ios');
    } else {
      if (window.commonJS) {
        window.commonJS.scanQRCode();
      }
    }
  },
  mounted() {
    window.onScanResult = this.onScanResult;
    // this.goScan()
  },

  destroyed() {
    if (this.video) {
      let tracks = this.video.srcObject.getTracks();
      tracks.forEach(i => i.stop());
      this.video.pause();
      this.video.src = '';
      this.video = null;
    }
  },
  methods: {
    goScan() {
      this.scanPopupShow = true;
      this.$nextTick(() => {
        let video = document.createElement('video');
        this.video = video;
        if (!this.canvas) {
          let canvasElement = document.getElementById('scanCanvas');
          this.canvas = canvasElement.getContext('2d');
        }
        navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment'
          }
        }).then(stream => {
          video.srcObject = stream;
          video.setAttribute('playsinline', true);
          video.play();
          video.onloadedmetadata = () => {
            let canvasElement = document.getElementById('scanCanvas');
            canvasElement.height = video.videoHeight;
            canvasElement.width = video.videoWidth;
            let wrapperEl = document.querySelector('.scanMain');
            let outWidth = wrapperEl.offsetWidth;
            let outHeight = canvasElement.height / canvasElement.width * outWidth;
            let scaleX = 1;
            let scaleY = 1;
            scaleY = outHeight / canvasElement.height;
            scaleX = outWidth / canvasElement.width;
            canvasElement.style.transform = 'scale(' + scaleX + ',' + scaleY + ')';
            this.init = true;
          };
          requestAnimationFrame(this.scanTick);
        });
      });
    },
    scanTick() {
      let video = this.video;
      let canvas = this.canvas;
      let canvasElement = document.getElementById('scanCanvas');
      if (video && this.init) {
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        let code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        });
        const drawLine = (begin, end, color) => {
          canvas.beginPath();
          canvas.moveTo(begin.x, begin.y);
          canvas.lineTo(end.x, end.y);
          canvas.lineWidth = 4;
          canvas.strokeStyle = color;
          canvas.stroke();
        };
        if (code) {
          drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58');
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58');
          drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58');
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58');
          setTimeout(() => {
            this.onScanResult(code.data);
          }, 500);
          return;
        }
      }
      requestAnimationFrame(this.scanTick);
    },
    onCloseScan() {
      this.colse();
    },
    onScanResult(val) {
      this.$dialog.confirm({
        message: '请确认是否核销？'
      }).then(() => {
        useUserCoupon({
          cdKey: val
        }).then(res => {
          this.$toast('核销成功');
          setTimeout(() => {
            this.colse();
          }, 1500);
        }).catch(() => {
          setTimeout(() => {
            this.colse();
          }, 1500);
        });
      }).catch(() => {
        // on cancel
        this.colse();
      });
    },
    colse() {
      if (this.phoneType === 'ios') {
        window.webkit.messageHandlers.goBack.postMessage('ios');
      } else {
        if (window.commonJS) {
          window.commonJS.closePage();
        }
      }
    }
  }
};